import { IIdentity } from "@/interfaces";
import {
  ICreateCommentData,
  IFolder,
  IFollowRequestData,
  IRequestBookmarkItemsData,
  IRequestBookmarkListData,
  IRequestCommentData,
  IRequestCompanyJobsData,
  IRequestFavoriteArtsData,
  IRequestLikedArtsData,
  IRequestNotificationData,
  ISearchArtworkLIst,
  ISearchAuthorLIst,
  ISearchMyArtworkList,
  ISubmitArtworkData,
} from "@/pages/discover/types";
import { axiosInstance } from "@/utils/http";
import { useOne } from "@refinedev/core";
import { message } from "antd";
import { RcFile } from "antd/es/upload";
import { list } from "postcss";

export const useUserProfile = (userId: string) => {
  const TOKEN_KEY = "leyline-auth";
  return useOne<IIdentity>({
    resource: "users",
    id: "profile",
    queryOptions: {
      enabled: !!userId && !!localStorage.getItem(TOKEN_KEY),
    },
  });
};

export const getFoldersAPI = async (
  currentRole: string,
): Promise<IFolder[]> => {
  try {
    const response = await axiosInstance.get("/discover/folder", {
      params: {
        role: currentRole,
      },
    });
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const createFolderAPI = async (
  currentRole: string,
  folderName: string,
) => {
  try {
    const response = await axiosInstance.post(
      "/discover/folder",
      {
        folder_name: folderName,
      },
      { params: { role: currentRole } },
    );
    message.success("Created folder successfully");
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw new Error(error.errors?.detail);
  }
};

export const editFolderAPI = async (folderId: string, folderName: string) => {
  try {
    const response = await axiosInstance.put(`/discover/folder/${folderId}`, {
      folder_name: folderName,
    });
    message.success("Edited folder name successfully");
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const deleteFolderAPI = async (folderId: string) => {
  try {
    const response = await axiosInstance.delete(`/discover/folder/${folderId}`);
    message.success("Deleted folder successfully");
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const createArtworkAPI = async (
  setLoading: (val: boolean) => void,
  data: ISubmitArtworkData,
  currentRole: string,
) => {
  try {
    setLoading(true);
    const response = await axiosInstance.post("/discover/artwork", data, {
      params: { role: currentRole },
    });
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  } finally {
    setLoading(false);
  }
};

export const getArtworkListAPI = async (
  setLoading: (val: boolean) => void,
  data: ISearchArtworkLIst,
) => {
  try {
    setLoading(true);
    const response = await axiosInstance.post("/discover/artwork/search", data);
    return {
      list: response.data.items,
      total: response.data.total,
      page: response.data.page,
      pages: response.data.pages,
    };
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  } finally {
    setLoading(false);
  }
};

export const getAuthArtworkListAPI = async (
  setLoading: (val: boolean) => void,
  currentRole: string,
  data: ISearchArtworkLIst,
) => {
  try {
    setLoading(true);
    const response = await axiosInstance.post(
      "/discover/artwork/search_auth",
      data,
      {
        params: {
          role: currentRole,
        },
      },
    );
    return {
      list: response.data.items,
      total: response.data.total,
      page: response.data.page,
      pages: response.data.pages,
    };
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  } finally {
    setLoading(false);
  }
};

export const getMyArtworkListAPI = async (
  setLoading: (val: boolean) => void,
  currentRole: string,
  data: ISearchMyArtworkList,
) => {
  try {
    setLoading(true);
    const response = await axiosInstance.post(
      "/discover/artwork/my/search",
      data,
      {
        params: {
          role: currentRole,
        },
      },
    );
    return {
      list: response.data.items,
      total: response.data.total,
      page: response.data.page,
      pages: response.data.pages,
    };
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  } finally {
    setLoading(false);
  }
};

export const updateArtworkAPI = async (
  data: ISubmitArtworkData,
  id: string,
) => {
  try {
    const response = await axiosInstance.put(`/discover/artwork/${id}`, data);
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const deleteArtworkAPI = async (id: string) => {
  try {
    const response = await axiosInstance.delete(`/discover/artwork/${id}`);
    message.success("Deleted artwork successfully");
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const getArtworkDetailAPI = async (
  setLoading: (val: boolean) => void,
  currentRole: string,
  id?: string,
) => {
  try {
    setLoading(true);
    const response = await axiosInstance.get(`/discover/artwork/${id}`, {
      params: { role: currentRole },
    });
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  } finally {
    setLoading(false);
  }
};

export const getArtworkGuestDetailAPI = async (
  setLoading: (val: boolean) => void,
  id?: string,
) => {
  try {
    setLoading(true);
    const response = await axiosInstance.get(`/discover/artwork/guest/${id}`);
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  } finally {
    setLoading(false);
  }
};

export const getAuthorsListAPI = async (
  setLoading: (val: boolean) => void,
  data: ISearchAuthorLIst,
) => {
  try {
    setLoading(true);
    const response = await axiosInstance.post(
      "/discover/artwork/authors",
      data,
    );
    return {
      list: response.data.items,
      total: response.data.total,
      page: response.data.page,
      pages: response.data.pages,
    };
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  } finally {
    setLoading(false);
  }
};

export const getAuthorsListAuthAPI = async (
  setLoading: (val: boolean) => void,
  role: string,
  data: ISearchAuthorLIst,
) => {
  try {
    setLoading(true);
    const response = await axiosInstance.post(
      "/discover/artwork/authors_auth",
      data,
      {
        params: {
          role: role,
        },
      },
    );
    return {
      list: response.data.items,
      total: response.data.total,
      page: response.data.page,
      pages: response.data.pages,
    };
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  } finally {
    setLoading(false);
  }
};

export const createCommentAPI = async (
  data: ICreateCommentData,
  role: string,
) => {
  try {
    const response = await axiosInstance.post("/discover/comment", data, {
      params: { role },
    });
    message.success("Comment added successfully");
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const createLikeAPI = async (
  artworkId: string,
  type: string,
  role: string,
) => {
  try {
    const response = await axiosInstance.post("/discover/likes", null, {
      params: {
        target_id: artworkId,
        type: type,
        role: role,
      },
    });
    if (type === "COMMENT") {
      message.success("Liked comment successfully");
    }
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const commentListCountAPI = async (artworkId: string) => {
  try {
    const response = await axiosInstance.get(
      `/discover/artwork/comment_count/${artworkId}`,
    );
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const getGuestCommentListCountAPI = async (artworkId: string) => {
  try {
    const response = await axiosInstance.get(
      `/discover/artwork/guest/comment_count/${artworkId}`,
    );
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const removeLikeAPI = async (
  artworkId: string,
  type: string,
  role: string,
) => {
  try {
    const response = await axiosInstance.delete("/discover/likes", {
      params: {
        target_id: artworkId,
        type: type,
        role: role,
      },
    });
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const createCollectionAPI = async (artworkId: string, role: string) => {
  try {
    const response = await axiosInstance.post(
      `/discover/collection/favorite/${artworkId}`,
      null,
      { params: { role } },
    );
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const removeCollectionAPI = async (artworkId: string, role: string) => {
  try {
    const response = await axiosInstance.delete(
      `/discover/collection/favorite/${artworkId}`,
      { params: { role } },
    );
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const getUserInfoAPI = async ({
  user_id,
  uniqueIdentifier,
}: { user_id?: string; uniqueIdentifier?: string }) => {
  try {
    const endPoint = uniqueIdentifier
      ? "/users/by-unique-identifier"
      : `/users/${user_id}/resume`;
    const params = uniqueIdentifier
      ? { unique_identifier: uniqueIdentifier }
      : {};
    const response = await axiosInstance.get(endPoint, {
      params,
    });
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const getCompanyInfoAPI = async ({
  user_id,
  uniqueIdentifier,
}: { user_id?: string; uniqueIdentifier?: string }) => {
  try {
    const endPoint = uniqueIdentifier
      ? "/companies/by-name"
      : "/companies/by-user";
    const params = uniqueIdentifier
      ? { company_name: uniqueIdentifier }
      : { user_id };
    const response = await axiosInstance.get(endPoint, {
      params,
    });
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const getCompanyJobsAPI = async (data: IRequestCompanyJobsData) => {
  try {
    const response = await axiosInstance.get("/jobs/published", {
      params: data,
    });
    return {
      list: response.data.items,
      total: response.data.total,
      page: response.data.page,
      pages: response.data.pages,
    };
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const getJobDetailAPI = async (job_id: string) => {
  try {
    const response = await axiosInstance.get(`/jobs/${job_id}`);
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const getGuestJobDetailAPI = async (job_id: string) => {
  try {
    const response = await axiosInstance.get(`/jobs/guest/${job_id}`);
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const getLikedArtsAPI = async (
  setLoading: (val: boolean) => void,
  user_id: string,
  data: IRequestLikedArtsData,
  role: string,
) => {
  try {
    setLoading(true);
    const response = await axiosInstance.post(
      `/discover/likes/arts/${user_id}`,
      data,
      { params: { role: role } },
    );
    return {
      list: response.data.items,
      total: response.data.total,
      page: response.data.page,
      pages: response.data.pages,
    };
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  } finally {
    setLoading(false);
  }
};

export const getFollowingAPI = async (
  setLoading: (val: boolean) => void,
  currentRole: string,
  data: IFollowRequestData,
) => {
  try {
    setLoading(true);
    const response = await axiosInstance.post(
      "/discover/follow/followees",
      data,
      { params: { role: currentRole } },
    );
    return {
      list: response.data.items,
      total: response.data.total,
      page: response.data.page,
      pages: response.data.pages,
    };
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  } finally {
    setLoading(false);
  }
};

export const getFollowersAPI = async (
  setLoading: (val: boolean) => void,
  currentRole: string,
  data: IFollowRequestData,
) => {
  try {
    setLoading(true);
    const response = await axiosInstance.post(
      "/discover/follow/followers",
      data,
      { params: { role: currentRole } },
    );
    return {
      list: response.data.items,
      total: response.data.total,
      page: response.data.page,
      pages: response.data.pages,
    };
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  } finally {
    setLoading(false);
  }
};

export const getCollectionArtsAPI = async (
  setLoading: (val: boolean) => void,
  data: IRequestFavoriteArtsData,
  role: string,
) => {
  try {
    setLoading(true);
    const response = await axiosInstance.post(
      "/discover/collection/favorites/search",
      data,
      { params: { role: role } },
    );
    return {
      list: response.data.items,
      total: response.data.total,
      page: response.data.page,
      pages: response.data.pages,
    };
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  } finally {
    setLoading(false);
  }
};

export const createFollowAPI = async (
  currentRole: string,
  setLoading: (val: boolean) => void,
  target_id: string,
  target_role: string,
) => {
  try {
    setLoading(true);
    const data = {
      target_id,
      target_role,
    };
    const response = await axiosInstance.post("/discover/follow", data, {
      params: {
        role: currentRole,
      },
    });
    message.success("Followed successfully");
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  } finally {
    setLoading(false);
  }
};

export const removeFollowAPI = async (
  currentRole: string,
  setLoading: (val: boolean) => void,
  target_id: string,
  target_role: string,
) => {
  try {
    setLoading(true);
    const data = {
      target_id,
      target_role,
    };
    const response = await axiosInstance.delete("/discover/follow", {
      data: data, // Specify the data in the `data` field
      params: { role: currentRole },
    });
    message.success("Unfollowed successfully");
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  } finally {
    setLoading(false);
  }
};

export const isFollowingAPI = async (
  currentRole: string,
  target_id: string,
  target_role: string,
) => {
  try {
    const data = {
      target_id,
      target_role,
    };
    const response = await axiosInstance.post(
      "/discover/follow/is_following",
      data,
      {
        params: { role: currentRole },
      },
    );
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const getFollowCountAPI = async (target_id: string, role: string) => {
  try {
    const response = await axiosInstance.post(
      `/discover/follow/follow_status/${target_id}`,
      null,
      { params: { role: role } },
    );
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const getBookmarkListAPI = async (
  setLoading: (val: boolean) => void,
  author_id: string,
  data: IRequestBookmarkListData,
  role: string,
) => {
  try {
    setLoading(true);
    const response = await axiosInstance.post(
      `/discover/collection/bookmarks/${author_id}`,
      data,
      {
        params: { role: role },
      },
    );
    return {
      list: response.data.items,
      total: response.data.total,
      page: response.data.page,
      pages: response.data.pages,
    };
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  } finally {
    setLoading(false);
  }
};

export const createBookmarkAPI = async (
  role: string,
  title: string,
  is_private: boolean,
) => {
  try {
    const response = await axiosInstance.post(
      "/discover/collection/bookmark",
      {
        title,
        is_private,
      },
      { params: { role } },
    );
    message.success("Created bookmark successfully");
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    console.log(error.errors);
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const updateBookmarkAPI = async (
  bookmark_id: string,
  title: string,
  is_private: boolean,
) => {
  try {
    const response = await axiosInstance.put(
      `/discover/collection/bookmark/${bookmark_id}`,
      {
        title,
        is_private,
      },
    );
    message.success("Updated bookmark successfully");
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const addArtworkToBookmarkAPI = async (
  bookmark_id: string,
  artwork_id: string,
) => {
  try {
    const response = await axiosInstance.post(
      `/discover/collection/bookmark/artwork/${bookmark_id}/${artwork_id}`,
    );
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const getBookmarkArtworkListAPI = async (
  setLoading: (val: boolean) => void,
  data: IRequestBookmarkItemsData,
) => {
  try {
    setLoading(true);
    const response = await axiosInstance.post(
      "/discover/collection/bookmark/items",
      data,
    );
    return {
      list: response.data.items,
      total: response.data.total,
      page: response.data.page,
      pages: response.data.pages,
    };
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  } finally {
    setLoading(false);
  }
};

export const removeBookmarkAPI = async (bookmark_id: string) => {
  try {
    const response = await axiosInstance.delete(
      `/discover/collection/bookmark/${bookmark_id}`,
    );
    message.success("Deleted bookmark successfully");
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const getCommentListAPI = async (
  artwork_id: string,
  role: string,
  data: IRequestCommentData,
) => {
  try {
    const response = await axiosInstance.post(
      `/discover/comment/search/${artwork_id}?role=${role}`,
      data,
    );
    return {
      list: response.data.items,
      total: response.data.total,
      page: response.data.page,
      pages: response.data.pages,
    };
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const getGuestCommentListAPI = async (
  artwork_id: string,
  data: IRequestCommentData,
) => {
  try {
    const response = await axiosInstance.post(
      `/discover/comment/guest/search/${artwork_id}`,
      data,
    );
    return {
      list: response.data.items,
      total: response.data.total,
      page: response.data.page,
      pages: response.data.pages,
    };
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const removeCommentAPI = async (comment_id: string) => {
  try {
    const response = await axiosInstance.delete(
      `/discover/comment/${comment_id}`,
    );
    message.success("Deleted comment successfully");
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const getNotificationUnreadCountAPI = async (
  role: string,
  type: string,
) => {
  try {
    const data = {
      type: type,
    };

    const response = await axiosInstance.post(
      "/discover/notification/unread-count",
      data,
      {
        params: { role },
      },
    );
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const getTotalNotificationUnreadCountAPI = async (role: string) => {
  try {
    const response = await axiosInstance.post(
      `/discover/notification/total-unread-count?role=${role}`,
    );
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const getNotficicationItemsAPI = async (
  role: string,
  data: IRequestNotificationData,
) => {
  try {
    const response = await axiosInstance.post(
      "/discover/notification/items",
      data,
      {
        params: { role },
      },
    );
    return {
      list: response.data.items,
      total: response.data.total,
      page: response.data.page,
      pages: response.data.pages,
    };
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const updateNotificationAPI = async (notification_id: string) => {
  try {
    const response = await axiosInstance.put(
      `/discover/notification/${notification_id}`,
    );
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const uploadCoverImageAPI = async (file: RcFile, apiPath: string) => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await axiosInstance.post(apiPath, formData);
    if (response.status === 200) {
      return response.data.id;
    }
    message.error("Failed to upload cover image");
    return null;
    // biome-ignore lint:
  } catch (error: any) {
    console.error("Error uploading cover image:", error);
    message.error(error?.response?.data?.detail);
    return null;
  }
};

export const updateCompanyOrUserInfoAPI = async (
  params: {
    cover_url?: string;
    page_cover_url?: string;
  },
  apiPath: string,
) => {
  try {
    const response = await axiosInstance.put(apiPath, params);
    if (response.status === 200) {
      message.success("Cover updated successfully.");
      return true;
    }
    console.error("Failed to information.");
    return false;
  } catch (error) {
    // @ts-ignore
    message.error(error?.errors?.detail || "Failed to information.");
    console.error("Error updating company information:", error);
    return false;
  }
};

export const deleteBulkArtworkAPI = async (ids: string[]) => {
  try {
    const response = await axiosInstance.post("/discover/artwork/bulk/remove", {
      artworks: ids,
    });
    message.success("Deleted artworks successfully");
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const artworkMoveToFolderAPI = async (
  folder_id: string,
  artwork_ids: string[],
) => {
  try {
    const response = await axiosInstance.post(
      `/discover/artwork/bulk/move/${folder_id}`,
      {
        artworks: artwork_ids,
      },
    );
    message.success("Moved artworks to folder successfully");
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};

export const getArtworkRecommendTagAPI = async (url: string) => {
  try {
    const response = await axiosInstance.post(`/discover/tagging?url=${url}`);
    return response.data;
    // biome-ignore lint:
  } catch (error: any) {
    message.error(error?.response?.data?.detail);
    throw error;
  }
};
