import { TreeMenuItem } from "@refinedev/core/dist/hooks/menu/useMenu";
import { ChatSDK } from "@tencentcloud/chat";
import { create } from "zustand";
import { UserType } from "../__generated__/graphql";

interface GlobalState {
  loading: boolean;
  chatClient: ChatSDK | null;
  currentRole: UserType | null;
  activeCol: string;
  menuItems: TreeMenuItem[];
  updateActiveCol: (col: string) => void;
  updateLoading: (loading: boolean) => void;
  updateChatClient: (chat: ChatSDK | null) => void;
  updateCurrentRole: (role: UserType) => void;
  updateMenuItems: (items: TreeMenuItem[]) => void;
}

export const useGlobalStore = create<GlobalState>((set) => ({
  activeCol: "portfolio",
  loading: false,
  chatClient: null,
  currentRole: null,
  menuItems: [],
  updateActiveCol: (col: string) => set(() => ({ activeCol: col })),
  updateLoading: (loading: boolean) => set(() => ({ loading })),
  updateChatClient: (chat) => set(() => ({ chatClient: chat })),
  updateCurrentRole: (newRole: UserType) =>
    set(() => ({ currentRole: newRole })),
  updateMenuItems: (items: TreeMenuItem[]) => set(() => ({ menuItems: items })),
}));

export default useGlobalStore;
