import {
  UserType,
  useSetRemoteCustomDataConversationMutation,
} from "@/__generated__/graphql";
import { useGlobalStore } from "@/stores";
import { setConversationCustomData } from "@/utils/imUtils";
import {
  AuditOutlined,
  EditOutlined,
  FileProtectOutlined,
  FileSearchOutlined,
  FrownOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import { useApiUrl } from "@refinedev/core";
import { Button, Modal, Typography, message } from "antd";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { JobContractModal } from "../../../pages/jobs/modalContract";
import { JobContractDetailModal } from "../../../pages/jobs/modalContractDetailPublic";
import { JobUnmatchModal } from "../../../pages/jobs/modalUnmatch";
import { formatRemainingTime, formatToLongStyle } from "../../../utils/date";
import { ApplicantInfo } from "../ArtistApplicantCard";
import CompanyInfoDrawer from "../components/CompanyInfoDrawer";
import ResumeDetailDrawer from "../components/ResumeDetailDrawer";

interface ConfigOpt {
  hideTip: boolean;
  hideTalkBtn: boolean;
  userId?: string;
  companyId?: string;
  role?: "Company" | "Individual";
}
const { Text } = Typography;

export const useApplicantButtons = (
  applicant: ApplicantInfo | undefined,
  refreshList: () => void,
  configOption?: ConfigOpt,
  isShow?: boolean,
) => {
  const [isUnmatchModalOpen, setIsUnmatchModalOpen] = useState(false);
  const [isContractModalOpen, setIsContractModalOpen] = useState(false);
  const [isContractDetailModalOpen, setIsContractDetailModalOpen] =
    useState(false);
  const [isResumeDrawerOpen, setIsResumeDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const apiUrl = useApiUrl();
  const { chatClient } = useGlobalStore((state) => state);
  const [setRemoteCustomDataMutation] =
    useSetRemoteCustomDataConversationMutation();
  const isHide = isShow === false;

  const updateApplication = async (
    action: string,
    reason?: string,
    remark?: string,
  ) => {
    const loadingMessage = message.loading(
      "Unmatching the application, please wait...",
      0,
    );
    try {
      const requestBody =
        action === "unmatch"
          ? {
              is_denied: 1,
              rejection_reason: reason,
              remark,
            }
          : {
              is_revoked: 1,
            };
      const response1 = await fetch(
        `${apiUrl}/applications/company/${applicant?.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("leyline-auth")}`,
          },
          body: JSON.stringify(requestBody),
        },
      );
      const data1 = await response1.json();
      // if (!data1.ok) {
      if (response1.status !== 200 && response1.status !== 204) {
        console.error(`Error ${action}`, data1);
        message.error("Failed to handle the application - try again later");
      } else {
        action === "unmatch" && setIsUnmatchModalOpen(false);
        refreshList();
      }
    } catch (error) {
      console.error(`Error ${action}`, error);
      message.error("Failed to handle the application - try again later");
    } finally {
      loadingMessage();
    }
  };

  const viewResume = useCallback(() => {
    setIsResumeDrawerOpen(true);
  }, []);

  const buttonConfigs = useMemo(
    () =>
      isHide
        ? null
        : {
            unsuitable: {
              title: "Reject Application",
              icon: <FrownOutlined />,
              onClick: () => {
                setIsUnmatchModalOpen(true);
              },
            },
            continueCommunication: {
              title: "Send Message",
              icon: <MessageOutlined />,
              onClick: async () => {
                const opponentImId = `${
                  applicant?.user_id
                }_${applicant?.role?.toLowerCase()}`;
                await setConversationCustomData(
                  chatClient,
                  setRemoteCustomDataMutation,
                  opponentImId,
                  {
                    jInfo: {
                      id: applicant?.job_id,
                      titl: applicant?.job_title,
                      pName: applicant?.project_name,
                      role: 2,
                    },
                  },
                );
                navigate("/enterprises/chat", {
                  state: {
                    opponentImId,
                    activeTab: 2,
                  },
                });
              },
            },
            viewResume: {
              title: "View Profile",
              icon: <FileSearchOutlined />,
              onClick: viewResume,
            },
            signNow: {
              title: "Sign Contract",
              icon: <AuditOutlined />,
              onClick: () => {
                setIsContractModalOpen(true);
              },
            },
            reviewTheContractContent: {
              title: "View Contract",
              icon: <FileProtectOutlined />,
              onClick: () => {
                setIsContractDetailModalOpen(true);
              },
            },
            revokeSign: {
              title: "Withdraw Contract",
              icon: <FrownOutlined />,
              onClick: () => {
                Modal.confirm({
                  content: "Would you like to revoke the signing?",
                  onOk() {
                    updateApplication("revokeSign");
                  },
                });
              },
            },
          },
    [
      applicant,
      navigate,
      updateApplication,
      chatClient,
      setRemoteCustomDataMutation,
      isHide,
      viewResume,
    ],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const showTipsAndActions = useMemo(() => {
    let tipsContent = null;
    let btns: string[] = [];
    if (isHide) return null;
    if (applicant?.status) {
      switch (applicant.status) {
        case "PENDING":
          tipsContent = (
            <Text type="warning">
              Please complete the process with{" "}
              {formatRemainingTime(applicant.created_at, 90)}.
            </Text>
          );
          btns = [
            "unsuitable",
            "continueCommunication",
            "viewResume",
            "signNow",
          ];
          break;
        case "A_DENIED":
          tipsContent = (
            <Text type="danger">
              Unsuitable:{" "}
              {applicant.remark || applicant.rejection_reason || "(no reason)"}
            </Text>
          );
          btns = ["continueCommunication", "viewResume"];
          break;
        case "B_DENIED":
          tipsContent = (
            <Text type="danger">
              Rejected:{" "}
              {applicant.remark || applicant.rejection_reason || "(no reason)"}
            </Text>
          );
          btns = ["continueCommunication", "viewResume"];
          break;
        case "A_SIGNED":
          tipsContent = (
            <Text type="warning">
              You have submitted the signing content. The other party will
              process it within{" "}
              {formatRemainingTime(applicant.contract?.signed_at as string, 3)}.
            </Text>
          );
          btns = [
            "continueCommunication",
            "revokeSign",
            "viewResume",
            "reviewTheContractContent",
          ];
          break;
        case "COMPLETED":
        case "B_SIGNED":
          tipsContent = (
            <Text type="success">
              Signing date:
              {formatToLongStyle(applicant.contract?.signed_at as string)}
            </Text>
          );
          btns = [
            "continueCommunication",
            "viewResume",
            "reviewTheContractContent",
          ];
          break;
        case "REFUSE_SIGN": // ????
          tipsContent = (
            <Text type="danger">
              Rejected: {applicant.rejection_reason || "(no reason)"}
            </Text>
          );
          btns = ["continueCommunication", "viewResume"];
          break;
        case "A_TIMEOUT":
          tipsContent = (
            <Text type="danger">
              You did not handle it in a timely manner. The current application
              has timed out.
            </Text>
          );
          btns = ["continueCommunication", "viewResume"];
          break;
        case "B_TIMEOUT":
          tipsContent = (
            <Text type="danger">
              Counterparty did not sign in a timely manner."
            </Text>
          );
          btns = ["continueCommunication", "viewResume"];
          break;
        case "A_CANCELLED":
          tipsContent = (
            <Text type="danger">You have revoked the signing.</Text>
          );
          btns = [
            "continueCommunication",
            "viewResume",
            "reviewTheContractContent",
          ];
          break;
        case "B_CANCELLED":
          tipsContent = (
            <Text type="danger">Counterparty have revoked the signing.</Text>
          );
          btns = [
            "continueCommunication",
            "viewResume",
            "reviewTheContractContent",
          ];
          break;
        case "REVOKE":
          tipsContent = (
            <Text type="danger">
              The other party has withdrawn the application.
            </Text>
          );
          btns = ["continueCommunication", "viewResume"];
          break;
        default:
          break;
      }
    } else {
      btns = ["viewResume"];
    }
    if (configOption?.hideTalkBtn) {
      const index = btns.findIndex(
        (btnName) => btnName === "continueCommunication",
      );
      index > -1 && btns.splice(index, 1);
    }
    return (
      <>
        <div className="flex justify-between items-center mt-3">
          {!!tipsContent && !configOption?.hideTip && (
            <p className="mb-0 mr-4">{tipsContent}</p>
          )}
          <div className="flex justify-end">
            {btns.map((item, index) => {
              // @ts-ignore
              const config = buttonConfigs[item] || {};
              return (
                <Button
                  key={config.title}
                  type="primary"
                  ghost={index < btns.length - 1}
                  className="mr-2"
                  onClick={config.onClick}
                  icon={config.icon}
                >
                  {config.title}
                </Button>
              );
            })}
          </div>
        </div>
        {!!applicant?.status && (
          <>
            <JobContractModal
              open={isContractModalOpen}
              setOpen={setIsContractModalOpen}
              refreshList={refreshList}
              applicant={applicant}
            />
            <JobContractDetailModal
              curRole="partyA"
              open={isContractDetailModalOpen}
              setOpen={setIsContractDetailModalOpen}
              refreshList={refreshList}
              application={applicant}
            />
            <JobUnmatchModal // @ts-ignore
              open={isUnmatchModalOpen} // @ts-ignore
              onOk={(reason, remark) => {
                updateApplication("unmatch", reason, remark);
              }}
              onCancel={() => {
                setIsUnmatchModalOpen(false);
              }}
            />
          </>
        )}
        {(!!applicant?.role || configOption?.role) && (
          <ResumeDetailDrawer
            open={isResumeDrawerOpen}
            userId={
              (applicant?.user_id as string) || (configOption?.userId as string)
            }
            userRole={(
              (applicant?.role as string) || (configOption?.role as string)
            )?.toUpperCase()}
            onCancel={() => setIsResumeDrawerOpen(false)}
          />
        )}
      </>
    );
  }, [applicant, buttonConfigs, isHide]);

  return { showTipsAndActions, viewResume };
};
