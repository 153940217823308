import {
  AuditOutlined,
  FolderOpenFilled,
  HomeFilled,
  LayoutFilled,
  LockOutlined,
  LogoutOutlined,
  MailOutlined,
  ProjectFilled,
  SettingOutlined,
  UserSwitchOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import type { GetProp, MenuProps } from "antd";
import { PiBuildingsFill } from "react-icons/pi";

type MenuItem = GetProp<MenuProps, "items">[number];

export const openKeys = ["/custom"];

const individualMenus = [
  {
    key: "/homepage",
    icon: <HomeFilled />,
    label: "Homepage",
  },
  {
    key: "/portfolio/home",
    icon: <FolderOpenFilled />,
    label: "Portfolio",
  },
  {
    key: "/users/home",
    icon: <MailOutlined />,
    label: "Applications",
  },
  {
    key: "/wallet/settlements",
    icon: <WalletOutlined />,
    label: "Wallet",
  },
  {
    key: "/setting",
    icon: <SettingOutlined />,
    label: "Settings",
  },
];

const companyMenus = [
  {
    key: "/homepage",
    icon: <HomeFilled />,
    label: "Homepage",
  },
  {
    key: "/portfolio/home",
    icon: <FolderOpenFilled />,
    label: "Portfolio",
  },
  {
    key: "/projects",
    icon: <ProjectFilled />,
    label: "My Projects",
  },
  {
    key: "/jobs",
    icon: <LayoutFilled />,
    label: "My Openings",
  },
  {
    key: "/users/home",
    icon: <MailOutlined />,
    label: "Applications",
  },
  {
    key: "/companies/show",
    icon: <PiBuildingsFill />,
    label: "My Company",
    // children: [
    //   { key: "/companies/show", label: "Company Information" },
    //   { key: "/companies", label: "Homepage" },
    // ],
  },
  {
    key: "/wallet/settlements",
    icon: <WalletOutlined />,
    label: "Wallet",
  },
  {
    key: "/setting",
    icon: <SettingOutlined />,
    label: "Settings",
  },
];

const getMenuData = (isCompanyRole: boolean): MenuItem[] =>
  isCompanyRole ? companyMenus : individualMenus;

export const renderFooterMenu = (isCompanyRole: boolean): MenuItem[] => {
  return [
    {
      type: "divider",
    },
    {
      key: "switchRole",
      icon: <UserSwitchOutlined />,
      label: `Switch to ${isCompanyRole ? "Personal" : "Company"} Role`,
    },
    {
      key: "/logout",
      icon: <LogoutOutlined />,
      label: "Logout",
    },
  ];
};

export const isPersonalCenterPage = (pathname?: string): boolean => {
  return (
    individualMenus.some((x) => x.key === pathname) ||
    companyMenus.some((x) => x.key === pathname)
  );
};

export default getMenuData;
