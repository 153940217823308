import { UserType } from "@/__generated__/graphql";
import SEO from "@/components/seo";
import ArtistPortfolioCore from "@/pages/discover/ArtistPortfolioCore";
import { SEOProps } from "@/utils/type";
import { useParsed } from "@refinedev/core";
import { FC, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

interface ArtistHomepageProps {
  type: string;
}

export const ArtistHomepage: FC<ArtistHomepageProps> = ({ type: userRole }) => {
  const { name } = useParams();

  const [seoInfo, setSeoInfo] = useState<SEOProps>({
    title: "",
    description: "",
    keywords: "",
    image: "",
    ogTitle: "",
    ogDescription: "",
  });

  const decodeName = useMemo(() => {
    return decodeURIComponent(name ?? "");
  }, [name]);

  return (
    <>
      {/* SEO */}
      <SEO
        title={seoInfo.title}
        description={seoInfo.description}
        keywords={seoInfo.keywords}
        ogTitle={seoInfo.ogTitle}
        ogDescription={seoInfo.ogDescription}
        image={seoInfo.image}
      />

      {/* Main page content */}
      <ArtistPortfolioCore
        hideBack
        uniqueIdentifier={decodeName}
        userRole={userRole}
        userType="artist"
        onSeoDataLoad={(data) => setSeoInfo(data)}
      />
    </>
  );
};
