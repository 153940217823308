import { UserType } from "@/__generated__/graphql";
import { checkApplyForJob } from "@/pages/enterprises/services";
import { ApplyInfoType } from "@/pages/enterprises/utils";
import { useGlobalStore } from "@/stores";
import { ShortUserType } from "@/utils/imUtils";
import { useRequest } from "ahooks";
import { Modal, message } from "antd";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

interface DrawerRefType {
  open: (info?: ApplyInfoType) => void;
}

export const useCheckJob = (id?: string) => {
  const { currentRole } = useGlobalStore((state) => state);
  const [modal, contextHolder] = Modal.useModal();
  const navigate = useNavigate();
  const applyDrawerRef = useRef<DrawerRefType>();

  const { loading: checkLoading, run: checkApplyApi } = useRequest(
    () =>
      checkApplyForJob({
        job_id: id || "",
        role: ShortUserType[currentRole as UserType],
      }),
    {
      manual: true,
      ready: !!id && !!currentRole,
      refreshDeps: [id, currentRole],
      onSuccess() {
        sessionStorage.setItem("currentJobIdToApply", id || "");
        applyDrawerRef.current?.open();
      },
      onError(err) {
        // @ts-ignore
        if (err.response?.headers?.["x-error"] === "20008") {
          modal.confirm({
            title: "Tips",
            // @ts-ignore
            content: err.response?.data?.detail,
            okText: "Publish Portfolio",
            onOk() {
              navigate("/portfolio/create");
            },
          });
        } else {
          // @ts-ignore
          message.error(err.response?.data?.detail || "");
        }
      },
    },
  );

  return { contextHolder, checkLoading, applyDrawerRef, checkApplyApi };
};
