import { isAllowClose } from "@/utils/common";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Drawer, Modal, Tooltip, message } from "antd";
import { Ref, forwardRef, useImperativeHandle, useRef, useState } from "react";
import { ApplyJobAlternative } from "../../../pages/enterprises";
import { ApplyInfoType } from "../../../pages/enterprises/utils";

interface Props {
  refresh: () => void;
}
const ApplyJobDrawer = (props: Props, ref: Ref<unknown> | undefined) => {
  const [visible, setVisible] = useState(false);
  const [applyInfo, setApplyInfo] = useState<ApplyInfoType>();
  const applyJobRef = useRef<{ checkFormChanged: () => boolean }>(null);
  const [modal, contextHolder] = Modal.useModal();

  useImperativeHandle(ref, () => ({
    open: (info: ApplyInfoType) => {
      setVisible(true);
      setApplyInfo(info);
    },
  }));

  const onSuccess = () => {
    setVisible(false);
    props.refresh();
  };

  const onClose = async () => {
    const hasChanged = applyJobRef.current?.checkFormChanged();
    const isAllow = await isAllowClose(hasChanged, modal);
    isAllow && setVisible(false);
  };

  const drawerTitle = (
    <div className="flex justify-between">
      <span>Apply Job</span>
      <Tooltip title="If you have a pending application for this project or are already part of the project, you cannot apply again.">
        <QuestionCircleOutlined />
      </Tooltip>
    </div>
  );

  return (
    <Drawer
      open={visible}
      // title={`${applyInfo ? "Modify" : "Create"} application information`}
      title={drawerTitle}
      rootClassName="top-20"
      onClose={onClose}
      placement="right"
      closable
      width={600}
      maskClosable={true}
      destroyOnClose
    >
      <ApplyJobAlternative
        ref={applyJobRef}
        applyInfo={applyInfo}
        onSuccess={onSuccess}
      />
      {contextHolder}
    </Drawer>
  );
};

export default forwardRef(ApplyJobDrawer);
