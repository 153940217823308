import { httpGet } from "@/utils/http";

export const getReceivedApplications = (params: {
  page: number;
  size: number;
}) =>
  httpGet("/applications/company/received", {
    params,
  });

export const getPublishedApplications = (params: {
  page: number;
  size: number;
  role?: string;
}) =>
  httpGet("/applications/applicant/published", {
    params,
  });
