import { JobInfoContextType, JobInfoProvider } from "@/contexts/jobInfo";
import { JobDetailData } from "@/interfaces";
import { useCheckJob } from "@/pages/enterprises/hooks/useCheckJob";
import { useGlobalStore } from "@/stores";
import { setConversationCustomData } from "@/utils/imUtils";
import {
  AuditOutlined,
  EditOutlined,
  FileProtectOutlined,
  FrownOutlined,
  MessageOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { useApiUrl } from "@refinedev/core";
import { Button, Modal, Typography, message } from "antd";
import React, { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  UserType,
  useSetRemoteCustomDataConversationMutation,
} from "../../../__generated__/graphql";
import { ApplyInfoType } from "../../../pages/enterprises/utils";
import { JobContractApplicantModal } from "../../../pages/jobs/modalContractApplicant";
import { JobContractDetailModal } from "../../../pages/jobs/modalContractDetailPublic";
import { JobUnmatchModal as RefuseModal } from "../../../pages/jobs/modalUnmatch";
import { formatRemainingTime, formatToLongStyle } from "../../../utils/date";
import { ApplicantInfo } from "../ArtistApplicantCard";
import ApplyJobDrawer from "../components/ApplyJobDrawer";

interface ConfigOpt {
  hideTip: boolean;
  hideTalkBtn: boolean;
  jobId: string;
}
interface DrawerRefType {
  open: (info?: ApplyInfoType) => void;
}
const { Text } = Typography;

export const useAppliedButtons = (
  applicant: ApplicantInfo | undefined,
  refreshList: () => void,
  configOption?: ConfigOpt,
  isShow?: boolean,
  jobData?: JobDetailData,
) => {
  const [isContractModalOpen, setIsContractModalOpen] = useState(false);
  const [isContractDetailModalOpen, setIsContractDetailModalOpen] =
    useState(false);
  const [isRefuseModalOpen, setIsRefuseModalOpen] = useState(false);
  const navigate = useNavigate();
  const apiUrl = useApiUrl();
  const { chatClient } = useGlobalStore((state) => state);
  const [setRemoteCustomDataMutation] =
    useSetRemoteCustomDataConversationMutation();
  const finallyJobId = configOption?.jobId || applicant?.job_id;
  const isHide = isShow === false;
  const {
    contextHolder,
    checkLoading,
    applyDrawerRef: editApplyInfoRef,
    checkApplyApi,
  } = useCheckJob(finallyJobId);

  const handleConfirmation = () => {
    (async () => {
      const loadingMessage = message.loading(
        "Cancelling the application, please wait...",
        0,
      );
      try {
        const response1 = await fetch(
          `${apiUrl}/applications/applicant/${applicant?.id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("leyline-auth")}`,
            },
            body: JSON.stringify({
              is_enabled: 0,
            }),
          },
        );
        const data1 = await response1.json();
        if (response1.status !== 200 && response1.status !== 204) {
          console.error("Error cancelling", data1);
          message.error("Failed to cancel the application - try again later");
        } else {
          message.success("cancelled the application - refreshing");
          refreshList();
        }
      } catch (error) {
        console.error("Error cancelling", error);
        message.error("Failed to cancel the application - try again later");
      } finally {
        loadingMessage();
      }
    })();
  };

  const updateApplication = async (
    action: string,
    reason?: string,
    remark?: string,
  ) => {
    const loadingMessage = message.loading(
      "Unmatching the application, please wait...",
      0,
    );
    try {
      const requestBody =
        action === "refuse"
          ? {
              is_denied: 1,
              rejection_reason: reason,
              remark,
              // role: "Individual",
            }
          : {};
      const response1 = await fetch(
        `${apiUrl}/applications/applicant/${applicant?.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("leyline-auth")}`,
          },
          body: JSON.stringify(requestBody),
        },
      );
      const data1 = await response1.json();
      if (response1.status !== 200 && response1.status !== 204) {
        console.error(`Error ${action}`, data1);
        message.error("Failed to handle the application - try again later");
      } else {
        action === "refuse" && setIsRefuseModalOpen(false);
        refreshList();
      }
    } catch (error) {
      console.error(`Error ${action}`, error);
      message.error("Failed to handle the application - try again later");
    } finally {
      loadingMessage();
    }
  };

  const buttonConfigs = useMemo(
    () =>
      isHide
        ? null
        : {
            continueCommunication: {
              title: "Send Message",
              icon: <MessageOutlined />,
              onClick: async () => {
                const opponentImId = `${applicant?.user_id}_company`;
                await setConversationCustomData(
                  chatClient,
                  setRemoteCustomDataMutation,
                  opponentImId,
                  {
                    jInfo: {
                      id: applicant?.job_id,
                      titl: applicant?.job_title,
                      pName: applicant?.project_name,
                      role: 1,
                    },
                  },
                );
                navigate("/enterprises/chat", {
                  state: {
                    opponentImId: `${applicant?.user_id}_company`,
                    activeTab: 1,
                  },
                });
              },
            },
            revokeApplication: {
              title: "Withdraw Application",
              icon: <FrownOutlined />,
              onClick: () => {
                Modal.confirm({
                  title: "Are you sure you want to withdraw the application?",
                  onOk() {
                    handleConfirmation();
                  },
                });
              },
            },
            modifyApplicationInfo: {
              title: "Edit Application",
              icon: <EditOutlined />,
              loading: checkLoading,
              onClick: () => {
                // checkApplyApi();
                sessionStorage.setItem(
                  "currentJobIdToApply",
                  finallyJobId || "",
                );
                editApplyInfoRef.current?.open(applicant);
              },
            },
            applyNow: {
              title: "Apply Now",
              loading: checkLoading,
              icon: <SmileOutlined />,
              onClick: () => {
                if (applicant?.is_job_available === 0) {
                  message.error(
                    "Application failed. The current job has been closed.",
                  );
                  return;
                }
                checkApplyApi();
              },
            },
            refuse: {
              title: "Reject Signing",
              icon: <FrownOutlined />,
              onClick: () => {
                setIsRefuseModalOpen(true);
              },
            },
            goToSign: {
              title: "Sign Contract",
              icon: <AuditOutlined />,
              onClick: () => {
                setIsContractModalOpen(true);
              },
            },
            reviewTheContractContent: {
              title: "View Contract",
              icon: <FileProtectOutlined />,
              onClick: () => {
                setIsContractDetailModalOpen(true);
              },
            },
          },
    [
      finallyJobId,
      applicant,
      navigate,
      handleConfirmation,
      chatClient,
      setRemoteCustomDataMutation,
      isHide,
      checkApplyApi,
      checkLoading,
      editApplyInfoRef,
    ],
  );

  const simpleJobData = useMemo(
    () =>
      jobData
        ? {
            project_name: jobData.project_name,
            project_id: jobData.project_id,
            company_id: jobData.company_id,
            job_title: jobData.title,
            job_id: jobData.id,
          }
        : null,
    [jobData],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const showTipsAndActions = useMemo(() => {
    let tipsContent: null | React.JSX.Element = null;
    let btns: string[] = [];
    if (isHide) return null;
    if (applicant?.status) {
      switch (applicant.status) {
        case "PENDING":
          tipsContent = (
            <Text type="warning">
              You have submitted your application and the other party will
              process it with {formatRemainingTime(applicant.created_at, 90)}.
            </Text>
          );
          btns = [
            // "continueCommunication",
            "revokeApplication",
            "modifyApplicationInfo",
          ];
          break;
        case "A_DENIED":
          tipsContent = (
            <Text type="danger">
              Unsuitable:{" "}
              {applicant.remark || applicant.rejection_reason || "(no reason)"}
            </Text>
          );
          btns = ["applyNow"];
          break;
        case "B_DENIED":
          tipsContent = (
            <Text type="danger">
              Rejected:{" "}
              {applicant.remark || applicant.rejection_reason || "(no reason)"}
            </Text>
          );
          btns = ["applyNow"];
          break;
        case "A_SIGNED":
          tipsContent = (
            <Text type="warning">
              Please complete the signing within{" "}
              {formatRemainingTime(applicant.contract?.signed_at as string, 3)}.
            </Text>
          );
          btns = ["refuse", "goToSign"];
          break;
        case "COMPLETED":
        case "B_SIGNED":
          tipsContent = (
            <Text type="success">
              Signing date:
              {formatToLongStyle(applicant.contract?.signed_at as string)}
            </Text>
          );
          btns = ["reviewTheContractContent"];
          break;
        case "A_CANCELLED":
          tipsContent = (
            <Text type="danger">Counterparty have revoked the signing.</Text>
          );
          btns = ["reviewTheContractContent"];
          break;
        case "B_CANCELLED":
          tipsContent = (
            <Text type="danger">You have revoked the signing.</Text>
          );
          btns = [
            // "continueCommunication",
            "applyNow",
            "reviewTheContractContent",
          ];
          break;
        case "A_TIMEOUT":
          tipsContent = (
            <Text type="danger">
              The project party did not handle it in a timely manner. We suggest
              you contact the project party for communication and then reapply.
            </Text>
          );
          btns = ["applyNow"];
          break;
        case "B_TIMEOUT":
          tipsContent = (
            <Text type="danger">
              You did not sign in a timely manner. We suggest you contact the
              project party for communication and then reapply.
            </Text>
          );
          btns = ["applyNow"];
          break;
        case "REVOKE":
          tipsContent = (
            <Text type="danger">You have withdrawn the application.</Text>
          );
          btns = ["applyNow"];
          break;
        default:
          break;
      }
    } else {
      btns = ["applyNow"];
    }
    if (configOption?.hideTalkBtn) {
      const index = btns.findIndex(
        (btnName) => btnName === "continueCommunication",
      );
      index > -1 && btns.splice(index, 1);
    }
    return (
      <>
        {contextHolder}
        <div className="flex justify-between items-center mt-3">
          {!!tipsContent && !configOption?.hideTip && (
            <p className="mb-0 mr-4">{tipsContent}</p>
          )}
          <div className="flex justify-end">
            {btns.map((item, index) => {
              // @ts-ignore
              const config = buttonConfigs[item] || {};
              return (
                <Button
                  key={config.title}
                  type="primary"
                  loading={config.loading}
                  ghost={index < btns.length - 1}
                  className="mr-2"
                  onClick={config.onClick}
                  icon={config.icon}
                >
                  {config.title}
                </Button>
              );
            })}
          </div>
        </div>
        {!!applicant?.status && (
          <>
            <JobContractApplicantModal
              open={isContractModalOpen}
              setOpen={setIsContractModalOpen}
              applied={applicant}
              refreshList={refreshList}
            />
            <JobContractDetailModal
              curRole="partyB"
              open={isContractDetailModalOpen}
              setOpen={setIsContractDetailModalOpen}
              refreshList={refreshList}
              application={applicant}
            />
            <RefuseModal // @ts-ignore
              type="refuse"
              open={isRefuseModalOpen} // @ts-ignore
              onOk={(reason, remark) => {
                updateApplication("refuse", reason, remark);
              }}
              onCancel={() => {
                setIsRefuseModalOpen(false);
              }}
            />
          </>
        )}
        {(!!applicant || simpleJobData) && (
          <JobInfoProvider
            jobData={applicant || (simpleJobData as JobInfoContextType)}
          >
            <ApplyJobDrawer ref={editApplyInfoRef} refresh={refreshList} />
          </JobInfoProvider>
        )}
      </>
    );
  }, [applicant, buttonConfigs, isHide]);

  return showTipsAndActions;
};
