import { ConfigProvider, Input, InputProps } from "antd";

interface Props extends InputProps {
  roundRadius?: boolean;
}

const handleSizeRadius = (
  roundRadius?: boolean,
  size?: "large" | "middle" | "small",
) => {
  switch (size) {
    case "large":
      return roundRadius ? 20 : 4;
    case "small":
      return roundRadius ? 12 : 0;
    default:
      return roundRadius ? 18 : 2;
  }
};

export const CustomInput = ({ roundRadius, ...props }: Props) => {
  const finalRadius = handleSizeRadius(roundRadius, props.size);
  return (
    <ConfigProvider
      theme={{
        token: {
          // colorTextPlaceholder: "#686868",
          // colorPrimary: "#00ADAE",
          // colorPrimaryHover: "#33BDBE",
          // colorPrimaryBorder: "#00ADAE",
          // colorLink: "#00ADAE",
          // colorTextPlaceholder: "#686868",
          // colorFillContent: "rgba(0, 173, 174, 0.10)",
          // controlOutline: "#2d2d2d",
          // controlTmpOutline: "#2d2d2d",
          // colorTextBase: "#ffffff",
        },
        components: {
          Input: {
            // colorBgContainer: "#292D31",
            // colorBorder: "transparent",
            borderRadiusXS: finalRadius,
            borderRadius: finalRadius,
            borderRadiusLG: finalRadius,
          },
        },
      }}
    >
      <Input
        styles={{
          affixWrapper: {
            borderRadius: finalRadius,
            // background: "#333",
            // border: "1px splid #333",
            // padding: "8px 12px",
          },
          // prefix: {
          //   color: "#606264",
          // },
          // input: {
          //   color: "white",
          // },
        }}
        {...props}
      />
    </ConfigProvider>
  );
};

CustomInput.Password = ({ roundRadius, ...props }: Props) => {
  const finalRadius = handleSizeRadius(roundRadius, props.size);
  return (
    <ConfigProvider
      theme={{
        token: {},
        components: {
          Input: {
            borderRadiusXS: finalRadius,
            borderRadius: finalRadius,
            borderRadiusLG: finalRadius,
          },
        },
      }}
    >
      <Input.Password
        styles={{
          affixWrapper: {
            borderRadius: finalRadius,
          },
        }}
        {...props}
      />
    </ConfigProvider>
  );
};

export default CustomInput;
