import { ConfigProvider, Typography } from "antd";
import { LinkProps } from "antd/es/typography/Link";

const { Link } = Typography;

export const CustomLink = ({ ...props }: LinkProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#00ADAE",
        },
      }}
    >
      <Link
        target="_blank"
        {...props}
        style={{
          color: "#00ADAE",
          ...props.style,
        }}
        className={`py-1 ${props.className} hover:!underline`}
      />
    </ConfigProvider>
  );
};
