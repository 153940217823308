import { paymentMethodOptions } from "@/pages/enterprises/utils";
import {
  unitforPaperworkOptions,
  unitforTimeOptions,
} from "@/utils/selectionList";
import { Form, FormInstance, InputNumber, Radio, Select, theme } from "antd";
import {
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

const { useToken } = theme;

interface Props {
  form: FormInstance;
  currency: string;
  hideCNY?: boolean;
  setCurrency: React.Dispatch<React.SetStateAction<string>>;
  normalLayout?: boolean;
}

export enum JobPaymentMethod {
  Paperwork = "Piece-rate",
  Time = "Time-based",
  Negotiable = "Negotiable",
  Undefined = "null",
}

export const PaymentMethodComp = (
  { form, currency, setCurrency, hideCNY = false, normalLayout = false }: Props,
  ref: Ref<unknown> | undefined,
) => {
  const [calculateRateUnit, setCalculateRateUnit] = useState("");
  const paymentMethod = Form.useWatch("payment_method", form);
  const totalAmount = Form.useWatch("totalAmount", form);
  const totalAmountEx = Form.useWatch("totalAmountEx", form);
  const { token } = useToken();

  useImperativeHandle(ref, () => ({
    transformFormValues: () => {
      const { headcount, budget } = form.getFieldsValue();
      const { quantity, duration_per_ppl, unit, unit_price } = budget || {};

      return {
        headcount,
        payment_method: paymentMethod,
        count:
          paymentMethod !== JobPaymentMethod.Negotiable &&
          paymentMethod !== JobPaymentMethod.Undefined
            ? paymentMethod === JobPaymentMethod.Paperwork
              ? quantity
              : parseFloat(duration_per_ppl)
            : null,
        count_unit:
          paymentMethod !== JobPaymentMethod.Negotiable &&
          paymentMethod !== JobPaymentMethod.Undefined
            ? unit
            : null,
        rate:
          paymentMethod !== JobPaymentMethod.Negotiable &&
          paymentMethod !== JobPaymentMethod.Undefined
            ? unit_price
            : null,
        rate_unit:
          paymentMethod === JobPaymentMethod.Time ? calculateRateUnit : "",
        total_cost:
          paymentMethod === JobPaymentMethod.Negotiable
            ? parseFloat(totalAmount)
            : parseFloat(totalAmountEx),
      };
    },
  }));

  const selectAfter = (
    <Select value={currency} onChange={setCurrency} style={{ width: 80 }}>
      <Select.Option value="USD">USD</Select.Option>
      {hideCNY ? null : <Select.Option value="CNY">CNY</Select.Option>}
    </Select>
  );

  // @ts-ignore
  const recalculatePrice = () => {
    const headcount = form?.getFieldValue("headcount");
    const budget = form?.getFieldValue("budget") || {};
    const { quantity, duration_per_ppl, unit, unit_price } = budget;

    if (!paymentMethod) return;
    if (!unit_price) form?.setFieldValue("totalAmountEx", "0.00");

    if (paymentMethod === JobPaymentMethod.Paperwork) {
      setCalculateRateUnit("");
      if (quantity) {
        const price = parseInt(quantity) * parseFloat(unit_price);
        form?.setFieldValue("totalAmountEx", price.toFixed(2));
      } else form?.setFieldValue("totalAmountEx", "0.00");
    } else if (paymentMethod === JobPaymentMethod.Time) {
      setCalculateRateUnit(unit ? `/person/${unit}` : "");
      if (headcount && duration_per_ppl) {
        const price =
          parseInt(headcount) *
          parseFloat(duration_per_ppl) *
          parseFloat(unit_price ?? 0);
        form?.setFieldValue("totalAmountEx", price.toFixed(2));
      } else form?.setFieldValue("totalAmountEx", "0.00");
    } else {
      setCalculateRateUnit("");
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    // @ts-ignore
    recalculatePrice();
  }, [paymentMethod]);

  return (
    <>
      <Form.Item
        label="Headcount"
        name="headcount"
        wrapperCol={{ span: 9 }}
        rules={[
          {
            required: true,
            message: "Please enter the headcount",
          },
          {
            type: "number",
            min: 1,
          },
          {
            type: "number",
            max: 9999,
          },
        ]}
      >
        <InputNumber
          controls={false}
          style={{ width: "100%" }}
          precision={0}
          onChange={(v) => {
            // @ts-ignore
            recalculatePrice(v);
          }}
        />
      </Form.Item>
      <Form.Item
        label="Payment Method"
        name="payment_method"
        rules={[{ required: true, message: "Please select a payment method" }]}
      >
        <Radio.Group
          options={paymentMethodOptions}
          optionType="button"
          buttonStyle="solid"
        />
      </Form.Item>

      {[JobPaymentMethod.Time, JobPaymentMethod.Paperwork].includes(
        paymentMethod,
      ) ? (
        <>
          {paymentMethod === JobPaymentMethod.Paperwork ? (
            <Form.Item
              label="Quantity (Total)"
              name={["budget", "quantity"]}
              wrapperCol={{ span: 9 }}
              rules={[
                { required: true },
                {
                  type: "number",
                  min: 0.01,
                },
                {
                  type: "number",
                  max: 999999,
                },
              ]}
            >
              <InputNumber
                controls={false}
                style={{ width: "100%" }}
                precision={2}
                onChange={(v) => {
                  // @ts-ignore
                  recalculatePrice(v);
                }}
              />
            </Form.Item>
          ) : (
            <Form.Item
              label="Duration (Per Person)"
              name={["budget", "duration_per_ppl"]}
              {...(normalLayout
                ? { labelCol: { span: 10 } }
                : { labelCol: { span: 8 } })}
              wrapperCol={{ span: 11 }}
              rules={[
                { required: true },
                {
                  type: "number",
                  min: 0.01,
                },
                {
                  type: "number",
                  max: 999999,
                },
              ]}
            >
              <InputNumber
                controls={false}
                style={{ width: "100%" }}
                precision={2}
                onChange={(v) => {
                  // @ts-ignore
                  recalculatePrice(v);
                }}
              />
            </Form.Item>
          )}
          <Form.Item
            label="Unit"
            name={["budget", "unit"]}
            rules={[{ required: true }]}
          >
            <Select
              style={{ width: 120 }}
              placeholder="Select unit"
              options={
                paymentMethod === JobPaymentMethod.Paperwork
                  ? unitforPaperworkOptions
                  : unitforTimeOptions
              }
              onChange={(v) => {
                // @ts-ignore
                recalculatePrice(v);
              }}
            >
              {/* Options  */}
            </Select>
          </Form.Item>
          <Form.Item
            label="Unit Price"
            name={["budget", "unit_price"]}
            rules={[
              { required: true },
              {
                type: "number",
                min: 0.01,
              },
              {
                type: "number",
                max: 999999999,
              },
            ]}
          >
            <InputNumber
              controls={false}
              style={{ width: "70%" }}
              precision={2}
              addonAfter={selectAfter}
              onChange={(v) => {
                // @ts-ignore
                recalculatePrice(v);
              }}
            />
          </Form.Item>
        </>
      ) : (
        <></>
      )}

      {paymentMethod === JobPaymentMethod.Negotiable ? (
        <Form.Item
          label="Total"
          name="totalAmount"
          rules={[
            {
              required: true,
              message: "Please enter the total amount",
            },
            {
              type: "number",
              min: 0.01,
            },
          ]}
        >
          <InputNumber
            controls={false}
            precision={2}
            addonAfter={selectAfter}
          />
        </Form.Item>
      ) : (
        <Form.Item
          label="Total Cost"
          name="totalAmountEx"
          rules={[
            {
              required: false,
              message: "Please enter the total amount",
            },
          ]}
        >
          <span
            className="font-bold w-[180]"
            style={{ color: token.colorTextBase }}
          >
            {currency} {totalAmountEx || 0}
          </span>
        </Form.Item>
      )}
    </>
  );
};

export default forwardRef(PaymentMethodComp);
