import { httpGet } from "@/utils/http";

export const getPublishedJobs = (params: {
  page: number;
  size: number;
  name: string;
  is_enabled?: number;
}) => {
  return httpGet("/jobs/published", {
    params,
  });
};
