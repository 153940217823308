import * as AllIcons from "@/components/customComponents/CustomIcons";
import Icon from "@ant-design/icons";
import type { GetProps } from "antd";
import { FC, useEffect, useRef } from "react";

type CustomIconComponentProps = GetProps<typeof Icon>;

interface Props extends CustomIconComponentProps {
  icon: string;
}

const iconsObj = {};
for (const compName in AllIcons) {
  // @ts-ignore
  const CompContent = AllIcons[compName];
  const shortName = compName.replace("Icon", "");
  // @ts-ignore
  iconsObj[shortName] = CompContent;
}

const LeyIcon: FC<Props> = (props) => {
  // @ts-ignore
  return <Icon component={iconsObj[props.icon] ?? null} {...props} />;
};

export default LeyIcon;
