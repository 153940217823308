import { theme } from "antd";
import { FC } from "react";

interface Props {
  fixedWidth?: boolean;
  text: string;
  color?: string;
  bgColor?: string;
  className?: string;
}

const { useToken } = theme;

export const CustomTag: FC<Props> = ({
  text,
  color,
  bgColor,
  className,
  fixedWidth,
}) => {
  const { token } = useToken();

  return (
    <span
      className={`inline-block border-[1px] border-solid ${
        fixedWidth ? "text-center" : "px-2"
      } rounded ${className}`}
      style={{
        borderColor: color,
        color: color ?? token.colorPrimary,
        backgroundColor: bgColor ?? token.colorFillContent,
      }}
    >
      {text}
    </span>
  );
};
