import { useUserData } from "@/hooks";
import { getStaticImgUrl } from "@/utils/common";
import { padImageUrl } from "@/utils/upload";
import { Image } from "antd";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import compVerifiedImg from "/images/compVerified.png";

interface Props {
  size?: "small" | "middle" | "large";
  is_verified: number | boolean;
  shortname: string;
  logo_url: string;
  className?: string;
  otherInfo?: {
    company_id: string;
    user_id: string;
    project_id: string;
  };
  onClick?: () => void;
}

const LOGO_SIZE_MAP = {
  small: 30,
  middle: 36,
  large: 70,
};
const TAG_SIZE_MAP = {
  small: 10,
  middle: 12,
  large: 22,
};

export const CompanyAvatar: FC<Props> = ({
  size = "large",
  is_verified,
  logo_url,
  shortname,
  className,
  otherInfo,
  onClick,
}) => {
  const navigate = useNavigate();
  const profile = useUserData();

  const goCompanyHome = () => {
    otherInfo?.user_id &&
      sessionStorage.setItem("jobtouserid", otherInfo.user_id);
    otherInfo?.project_id &&
      sessionStorage.setItem("jobtoprojectid", otherInfo.project_id);
    otherInfo?.company_id &&
      sessionStorage.setItem("jobtocompanyid", otherInfo.company_id);

    if (profile) {
      navigate("/enterprises/public-company-homepage");
    } else {
      navigate("/login");
    }
  };

  return (
    <div
      className={`flex relative border-2 border-solid ${
        is_verified ? "border-[#03B3B4]" : ""
      } rounded-full ${className}`}
      onClick={(e) => e.stopPropagation()}
      onKeyUp={() => {}}
    >
      <Image
        src={
          logo_url
            ? `${padImageUrl(logo_url)}`
            : getStaticImgUrl("logo/L-logo-2.png") || undefined
        }
        alt={shortname || ""}
        preview={false}
        onClick={onClick || goCompanyHome}
        className="rounded-full bg-[#999]"
        style={{
          width: LOGO_SIZE_MAP[size],
          height: LOGO_SIZE_MAP[size],
          objectFit: "cover",
        }}
      />
      {!!is_verified && (
        <Image
          rootClassName={`absolute left-1/2 ${
            size === "small"
              ? "-top-[12px]"
              : size === "middle"
                ? "-top-[11px]"
                : "-top-[10px]"
          } ${
            size === "small"
              ? "-ml-[5px]"
              : size === "middle"
                ? "-ml-[6px]"
                : "-ml-[11px]"
          }`}
          src={compVerifiedImg}
          preview={false}
          width={TAG_SIZE_MAP[size]}
          height={TAG_SIZE_MAP[size]}
        />
      )}
    </div>
  );
};
